import React, { useCallback, useEffect, useImperativeHandle, useState } from 'react';

import API from 'app/API';
import usePersistent from 'hooks/use-persistent';
import { useConfigs } from 'main/modules/configs';
import { logger } from 'services/logger';
import waitScriptReady from 'utils/wait-for-script-ready';

interface Props {
    id: string;
    callback?: () => void;
    invisible?: boolean;
    style?: React.CSSProperties;
}

export interface CaptchaRef {
    recoverCaptchaId: () => void;
    resetValue: () => void;
    getValue: () => string;
}

export const Captcha: React.ForwardRefRenderFunction<CaptchaRef, Props> = ({ id, callback, invisible, style }, ref) => {
    const { captchaDisabled } = useConfigs();
    const [state, setState] = useState({ captchaId: '' });
    const callbackRef = usePersistent(callback);

    const initCaptcha = useCallback(
        (sitekey: string) => {
            if (captchaDisabled) {
                return;
            }

            setState({
                captchaId: window.grecaptcha.render(document.getElementById(id), {
                    sitekey,
                    callback: callbackRef.current
                })
            });
        },
        [callbackRef, captchaDisabled, id]
    );

    const recoverCaptchaId = useCallback(async () => {
        if (captchaDisabled) {
            return;
        }

        await waitScriptReady('google-recaptcha-script', { alwaysResolve: true });
        const response = await API.fetch(API.CAPTCHA);
        const publickey = response?.data?.publickey;
        if (publickey) {
            try {
                initCaptcha(publickey);
            } catch (error) {
                logger.error('[Captcha]', error);
                setTimeout(() => initCaptcha(publickey), 1000);
            }
        } else {
            logger.error('[Captcha] Public key is not defined', response);
        }
    }, [captchaDisabled, initCaptcha]);

    const getValue = useCallback(() => {
        if (captchaDisabled) {
            return '';
        }
        return window.grecaptcha.getResponse(state.captchaId);
    }, [captchaDisabled, state.captchaId]);

    const resetValue = useCallback(() => {
        if (captchaDisabled) {
            return;
        }
        window.grecaptcha.reset();
    }, [captchaDisabled]);

    useEffect(() => {
        recoverCaptchaId();
    }, [recoverCaptchaId]);

    useImperativeHandle(
        ref,
        () => ({
            recoverCaptchaId,
            getValue,
            resetValue
        }),
        [getValue, recoverCaptchaId, resetValue]
    );

    return captchaDisabled ? null : (
        <div id={id} style={style} {...(invisible ? { ['data-size']: 'data-size' } : {})} className="g-recaptcha" />
    );
};

export default React.forwardRef(Captcha);
