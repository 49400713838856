import { action, makeObservable, observable } from 'mobx';
import { logger } from 'services/logger';

import API from 'app/API';
import isomorphicLocalStorage from 'main/modules/isomorphic-local-storage';
import isOkResponse from 'utils/is-ok-response';

export default class Subscription {
    static PENDING_SUBSCRIPTION_KEY = 'VULNERS_PENDING_SUBSCRIPTION';
    static SERIALIZE_KEYS = ['crontab', 'email', 'query', 'query_type', 'link_query', 'format', 'active', 'license_id']; // Strict fields to prevent XSS

    @observable id;
    @observable crontab = '';
    @observable email = '';
    @observable query = '';
    @observable query_type = 'lucene';
    @observable link_query = '/search';
    @observable format = 'html';
    @observable license_id = '';
    @observable active = true;

    @observable errorMessage = '';
    @observable successMessage = '';

    @observable loading = false;

    constructor(userStore, serverModel) {
        makeObservable(this);
        this.userStore = userStore;
        if (serverModel) {
            Object.keys(serverModel).forEach((key) => (this[key] = serverModel[key]));
            this.email = serverModel.deliveryAddress || this.email;
            this.format = serverModel.deliveryFormat || this.format;
        }
    }

    handleErrorResponse(response) {
        if (response?.data?.errorCode === 147 || response?.data?.errorCode === 150) {
            this.errorMessage = [
                response?.data?.error,
                "If you'd like to extend your usage license of subscriptions, please contact us on support@vulners.com"
            ].join('\n');
        } else {
            this.errorMessage =
                response?.data?.error || 'Something went wrong. Please, contact us on support@vulners.com';
        }
    }

    @action
    save = (onSuccess) => {
        const { crontab, email, query, query_type, link_query, license_id, format } = this;

        switch (true) {
            case !query:
                this.errorMessage = 'Query is required';
                return;
            case !crontab:
                this.errorMessage = 'Empty schedule provided';
                return;
            case !format:
                this.errorMessage = 'Empty format provided';
                return;
            case !email:
                this.errorMessage = 'Email is required';
                return;
            case !link_query:
                this.errorMessage = 'Query link is required';
                return;
            default:
                break;
        }

        this.loading = true;
        this.clearMessages();

        return API.fetchWithToken(API.SUBSCRIPTIONS_EMAIL_ADD, {
            crontab,
            email,
            query,
            query_type,
            link_query,
            license_id,
            format
        })
            .then((response) => {
                if (!isOkResponse(response)) {
                    return this.handleErrorResponse(response);
                }

                this.userStore.loadEmailSubscriptions();
                this.successMessage = 'New subscription successfully created';
                onSuccess && onSuccess();
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action updateActivity = async (isActive, onSuccess) => {
        const { id, query, format, crontab, active } = this;
        if (isActive === active) {
            return;
        }

        this.loading = true;
        this.clearMessages();
        try {
            const response = await API.fetchWithToken(API.SUBSCRIPTIONS_EMAIL_EDIT, {
                subscriptionid: id,
                query,
                format,
                crontab,
                active: String(isActive)
            });
            if (isOkResponse(response)) {
                this.active = isActive;
                return onSuccess?.();
            } else {
                this.handleErrorResponse(response);
            }
        } catch (error) {
            this.errorMessage = error.message;
        } finally {
            this.loading = false;
        }
    };

    @action
    toggleActive = () => this.updateActivity(!this.active);

    @action
    activate = () => this.updateActivity(true);

    @action
    deactivate = () => this.updateActivity(false);

    @action
    changeFormat = (format) => {
        this.loading = true;
        this.clearMessages();
        const { id, query, crontab, active } = this;

        API.fetchWithToken(API.SUBSCRIPTIONS_EMAIL_EDIT, {
            subscriptionid: id,
            query,
            format,
            crontab,
            active: String(active)
        })
            .then((response) => {
                if (!isOkResponse(response)) {
                    return this.handleErrorResponse(response);
                }

                this.format = format;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    changeCrontab = (crontab) => {
        this.loading = true;
        this.clearMessages();
        const { id, query, format, active } = this;

        API.fetchWithToken(API.SUBSCRIPTIONS_EMAIL_EDIT, {
            subscriptionid: id,
            query,
            format,
            crontab,
            active: String(active)
        })
            .then((response) => {
                if (!isOkResponse(response)) {
                    return this.handleErrorResponse(response);
                }

                this.crontab = crontab;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    delete = ({ reload = true } = {}) => {
        this.loading = true;
        this.clearMessages();
        API.fetchWithToken(API.SUBSCRIPTIONS_EMAIL_REMOVE, { subscriptionid: this.id })
            .then((response) => {
                if (!isOkResponse(response)) {
                    return this.handleErrorResponse(response);
                }
                if (reload) {
                    this.userStore.loadEmailSubscriptions();
                }
            })
            .catch((error) => {
                this.errorMessage = error.message;
            })
            .finally(() => {
                this.loading = false;
            });
    };

    @action
    hasPendingState(restoreKey) {
        const state = isomorphicLocalStorage.getItem(Subscription.PENDING_SUBSCRIPTION_KEY);
        if (state) {
            if (restoreKey) {
                return JSON.parse(state).restoreKey === restoreKey;
            }
            return true;
        }
        return false;
    }

    @action
    savePendingState = (restoreKey) => {
        const data = Subscription.SERIALIZE_KEYS.reduce((acc, key) => ({ ...acc, [key]: this[key] }), {});
        if (restoreKey) {
            Object.assign(data, { restoreKey });
        }
        return isomorphicLocalStorage.setItem(Subscription.PENDING_SUBSCRIPTION_KEY, JSON.stringify(data));
    };

    @action
    completePendingState = (onSuccess) => {
        let state = {};
        try {
            state = JSON.parse(isomorphicLocalStorage.getItem(Subscription.PENDING_SUBSCRIPTION_KEY) ?? '{}');
        } catch (error) {
            logger.error(error);
        }
        Subscription.SERIALIZE_KEYS.forEach((key) => (this[key] = state[key]));
        this.email ||= this.userStore.user.email;
        this.save(() => {
            onSuccess?.();
            isomorphicLocalStorage.removeItem(Subscription.PENDING_SUBSCRIPTION_KEY);
        });
    };

    @action
    clearMessages = () => {
        this.errorMessage = '';
        this.successMessage = '';
    };

    @action
    setCrontab = (crontab) => (this.crontab = crontab);

    @action
    setEmail = (email) => (this.email = email);

    @action
    setQuery = (query) => (this.query = query);

    @action
    setFormat = (format) => (this.format = format);

    @action
    setActive = (active) => (this.active = active);

    @action
    setQueryType = (type) => (this.query_type = type);

    @action
    setLinkQuery = (linkQuery) => (this.link_query = linkQuery);

    @action
    setLicenseId = (licenseId) => (this.license_id = licenseId);
}
