import { action, computed, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import {
    ElasticQueryBuilder,
    ElasticQueryRunner,
    ElasticSearchDriver,
    ElasticStateBuilder
} from 'main/modules/elastic';
import get from 'utils/get';
import isEmpty from 'utils/is-empty';
import isOkResponse from 'utils/is-ok-response';

export default class SearchStore extends Cacheable {
    static DEFAULT_QUERY = 'order:published';
    static DEFAULT_PAGE_SIZE = 20;
    /** @type {Partial<import('@elastic/search-ui').RequestState>} */
    static INITIAL_STATE = {
        sortField: 'published',
        sortDirection: 'desc'
    };
    static VIEWS = {
        LIST: 'list',
        TABLE: 'table'
    };

    @observable data = [];
    @observable occurrences = {};
    @observable total = 0;
    @observable exactMatch = null;

    @observable query = '';
    @observable lastSearchQuery = '';
    @observable software = '';
    @observable version = '';
    @observable type = 'simple';
    @observable view = SearchStore.VIEWS.LIST;

    @observable isLoading = false;
    @observable settingsOpen = false;
    @observable productSearchEnabled = true;

    @observable search = [];

    @observable pageSize = SearchStore.DEFAULT_PAGE_SIZE;

    isElasticEnabled = () => this.type === 'elastic';

    elasticSearchDriver = new ElasticSearchDriver({
        debug: false,
        initialState: SearchStore.INITIAL_STATE,
        enabled: this.isElasticEnabled,
        onSearch: this.onElasticSearch.bind(this)
    });
    elasticQueryBuilder = new ElasticQueryBuilder({ debug: false });
    elasticQueryRunner = new ElasticQueryRunner({ debug: false });
    elasticStateBuilder = new ElasticStateBuilder({ debug: false });

    updatingRequestState = false;
    /**
     * @type {import('@elastic/search-ui').RequestState}
     */
    @observable requestState = this.elasticSearchDriver.requestState;

    @computed get hasTerms() {
        return Object.entries(this.requestState)
            .filter(
                ([key, _value]) => ['current', 'resultsPerPage', 'sortField', 'sortDirection'].includes(key) === false
            )
            .some(([_key, value]) => isEmpty(value, { recursive: true }) === false);
    }

    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();

        makeObservable(this);

        this.elasticSearchDriver.subscribeToStateChanges(() => {
            if (this.updatingRequestState === false) {
                this.updatingRequestState = true;
                queueMicrotask(() => {
                    this.requestState = this.elasticSearchDriver.requestState;
                    this.updatingRequestState = false;
                });
            }
        });
    }

    consume(cachedEntry) {
        if (cachedEntry.data) {
            this.exactMatch = cachedEntry.data.exactMatch;
            this.data = cachedEntry.data.search;
            this.occurrences = cachedEntry.data.occurrences;
            this.total = cachedEntry.data.total;
        }
        if (cachedEntry.elastic) {
            this.data = cachedEntry.elastic.data;
            this.total = cachedEntry.elastic.total;
            this.exactMatch = cachedEntry.elastic.exactMatch;
            this.occurrences = cachedEntry.elastic.occurrences;
            this.elasticSearchDriver.setState(cachedEntry.elastic.driverState);
        }
        if (cachedEntry.params) {
            Object.assign(this, cachedEntry.params);
        }
    }

    // TODO: выпилить, заменив на this.setParameters + this.performFetch
    @action
    doSearch(options = {}) {
        const { query = SearchStore.DEFAULT_QUERY, skip, callBack } = options;
        this.setQuery(query);
        return this.doRequest({ query, skip, callBack });
    }

    @action
    setParameters = (params = {}) => {
        const { query, software, version, type, state } = params;
        this.elasticSearchDriver.setState({ ...this.elasticSearchDriver.startingState, ...state });
        Object.assign(this, { query, software, version, type });
        this.setItemToCache('params', { params });
    };

    // TODO: выпилить, заменив на this.setParameters
    @action
    setQuery(query) {
        this.query = query || '';
    }

    @action
    setType(type) {
        this.type = type || this.type;
    }

    @action
    openSettings() {
        this.settingsOpen = !this.settingsOpen;
    }

    @action
    setView(view) {
        this.view = view || this.view;
    }

    // TODO: возможно тоже выпилить, надо изучить use cases
    @action
    async doRequest(options) {
        const { query, skip, callBack } = options;

        try {
            await this.fetchSimpleSearch({ query, skip });
            if (typeof callBack === 'function') callBack();
        } finally {
            this.lastSearchQuery = query;
        }
    }

    @action
    resetParameters() {
        this.query = '';
        this.lastSearchQuery = '';
        this.software = '';
        this.version = '';
        this.type = 'simple';
        this.elasticSearchDriver.setState(this.elasticSearchDriver.startingState);
    }

    @action
    resetData() {
        this.data = [];
        this.total = 0;
        this.exactMatch = null;
    }

    @action
    setPageSize(size) {
        this.pageSize = size || SearchStore.DEFAULT_PAGE_SIZE;
    }

    /**
     * @method
     * @param {object} options
     * @param {string} options.query
     * @param {number | string=} options.skip
     */
    @action
    async fetchSimpleSearch(options) {
        if (this.isLoading) return;

        const { query, skip } = options;

        const request = API.SEARCH_V2;
        const queryKey = JSON.stringify([request.url, query ?? '', skip ?? null, this.pageSize]);
        if (this.hasItemInCache(queryKey)) return this.consume(this.getItemFromCache(queryKey));

        const data = {
            query: query,
            size: this.pageSize,
            fields: [
                'bulletinFamily',
                'cvss',
                'description',
                'id',
                'lastseen',
                'modified',
                'published',
                'title',
                'type',
                'vhref',
                'viewCount',
                'href',
                'enchantments',
                'bounty',
                'sourceData',
                'cvss3',
                'cvss2',
                'epss',
                'wildExploited'
            ]
        };

        if (skip) data['skip'] = parseInt(String(skip));

        this.isLoading = true;

        try {
            let response = await API.fetch(request, data);
            if (process.env.NODE_ENV !== 'production' && response.data && !response.data.search.length) {
                response = require('../../tests/mocks/search.json');
            }
            if (isOkResponse(response)) {
                const { exactMatch, search = [], occurrences = {}, total } = response.data;

                this.exactMatch = exactMatch;
                this.occurrences = occurrences;
                this.data = search;
                this.total = total;

                this.setItemToCache(queryKey, { data: { exactMatch, occurrences: this.occurrences, search, total } });
            }
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * @method
     * @param {object} options
     * @param {string} options.software
     * @param {string} options.version
     * @param {'cpe' | 'software' =} options.type
     * @param {number | string =} options.skip
     */
    @action
    async fetchProductSearch(options) {
        if (this.isLoading) return;

        const { software, version, type = 'software', skip } = options;

        const request = API.SEARCH_SOFTWARE;
        const queryKey = JSON.stringify([
            request.url,
            software ?? '',
            version ?? '',
            type,
            skip ?? null,
            this.pageSize
        ]);
        if (this.hasItemInCache(queryKey)) return this.consume(this.getItemFromCache(queryKey));

        const data = { software, version, type };
        if (skip) data['skip'] = parseInt(String(skip));

        this.isLoading = true;

        try {
            let productSearchEnabled = true;
            const response = await API.fetch(request, data);
            // if (process.env.NODE_ENV !== 'production' && !response.data?.search?.length) {
            //     response = require('../../tests/mocks/search.json');
            // }
            if (isOkResponse(response)) {
                const { exactMatch, search = [], occurrences = {}, total } = response.data;

                this.exactMatch = exactMatch;
                this.occurrences = occurrences;
                this.data = search;
                this.total = total;

                this.setItemToCache(queryKey, { data: { exactMatch, occurrences: this.occurrences, search, total } });
            } else {
                // "License requests count exceeded"
                if (response.data.errorCode === 900) {
                    productSearchEnabled = false;
                }
                // 401 - "Nothing found for Burpsuite search request"
                // 104 - "Invalid `version` or `software` value"
                // And others...
                this.resetData();
            }

            // Если явно не получили 900 - то возвращаем флаг в true
            this.productSearchEnabled = productSearchEnabled;
        } finally {
            this.isLoading = false;
        }
    }

    async onElasticSearch(state) {
        if (this.isLoading) return;

        const { query, queryKey } = this.elasticQueryBuilder.getQuery({ ...state, resultsPerPage: this.pageSize });

        if (this.hasItemInCache(queryKey)) {
            const { elastic } = this.getItemFromCache(queryKey);
            this.consume({ elastic });
            return elastic;
        }

        this.isLoading = true;

        try {
            const response = await this.elasticQueryRunner.run(query);

            this.data = response.data.hits.hits;
            this.total = response.data.hits.total;
            this.exactMatch = response.data.exactMatch;
            this.occurrences = {};

            const driverState = await this.elasticStateBuilder.buildState({
                state,
                hits: get(response, ['data', 'hits', 'hits'], []),
                aggregations: get(response, ['data', 'aggregations'], []),
                totalResults: get(response, ['data', 'hits', 'total'], 0),
                disjunctiveFacetNames: ['type', 'bulletinFamily', 'сvss', 'score']
            });

            this.setItemToCache(queryKey, {
                elastic: {
                    data: this.data,
                    occurrences: this.occurrences,
                    total: this.total,
                    exactMatch: this.exactMatch,
                    driverState
                }
            });
            return driverState;
        } catch (_error) {
            this.resetData();
        } finally {
            this.isLoading = false;
        }
    }

    /**
     * @method
     * @param {object} options
     * @param {string} options.query
     * @param {number | string =} options.skip
     */
    @action
    async fetchElasticSearch(options) {
        const resultsPerPage = this.pageSize;
        const skip = parseInt(String(options.skip)) || 0;
        const current = Math.floor(skip / resultsPerPage) + 1;
        this.elasticSearchDriver.setState({ searchTerm: options.query.trim(), resultsPerPage, current });
        return this.elasticSearchDriver.refetch();
    }

    /**
     * @method
     * @param {object} options
     */
    @action
    async performFetch(options = {}) {
        const { skip } = options;

        if (this.type === 'simple') {
            await this.fetchSimpleSearch({ query: this.query, skip });
        }

        if (this.type === 'product') {
            await this.fetchProductSearch({ software: this.software, version: this.version, skip });
        }

        if (this.type === 'elastic') {
            await this.fetchElasticSearch({ query: this.query, skip });
        }
    }

    @computed get hasNext() {
        return this.data.length >= this.pageSize && this.total > this.pageSize;
    }
}
