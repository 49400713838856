import { useRef } from 'react';

/**
 * @description Записывает аргумент в ref и поддерживает в актуальном состоянии,
 * возвращает созданный ref
 */
function usePersistent<T>(value: T) {
    const persistent = useRef<T>(value);
    persistent.current = value;
    return persistent;
}

export default usePersistent;
