import { createContext } from 'react';

import type { Config } from 'server/types';

const configsContext = createContext<Config>({
    mode: 'unknown' as never,
    environment: 'unknown' as never,
    device: {},
    settings: {}
});

export default configsContext;
