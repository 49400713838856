import mediaQuery from 'css-mediaquery';

import type { PaletteColor, PaletteColorOptions } from '@mui/material/styles';
import { createTheme } from '@mui/material/styles';

import { Config } from 'server/types';

declare module '@mui/material/styles' {
    interface Theme {
        customPalette: {
            common: Record<string, string>;
        };
    }

    interface Palette {
        default: PaletteColor;
    }

    interface PaletteOptions {
        default: PaletteColorOptions;
    }
    interface ThemeOptions {
        customPalette?: {
            common: Record<string, string>;
        };
    }
}

const OrangeTheme = (config?: Config) =>
    createTheme({
        typography: {
            fontFamily: ['Inter', 'sans-serif'].join(','),
            h1: { color: '#282D31', fontSize: 38, fontWeight: 800 },
            h2: { color: '#282D31', fontSize: 36, fontWeight: 800 },
            h3: { color: '#282D31', fontSize: 24, fontWeight: 800 },
            h4: { color: '#282D31', fontSize: 20, fontWeight: 400 },
            h5: { color: '#282D31', fontSize: 18, fontWeight: 400 },
            h6: { color: '#282D31', fontSize: 16, fontWeight: 400 },
            subtitle1: { color: '#282D31', fontSize: 24, fontWeight: 'normal' },
            subtitle2: { color: '#282D31', fontSize: 20, fontWeight: 'normal' },
            body1: { color: '#282D31', fontSize: 16 },
            body2: { color: '#282D31', fontSize: 14 },
            button: { color: '#282D31', fontSize: 12 },
            caption: { color: '#282D31' },
            overline: { color: '#282D31' }
        },
        breakpoints: {
            keys: ['xs', 'sm', 'md', 'lg', 'xl'],
            values: { xs: 0, sm: 600, md: 960, lg: 1280, xl: 1920 }
        },
        direction: 'ltr',
        mixins: {
            toolbar: {
                minHeight: 56,
                '@media (min-width:0px) and (orientation: landscape)': {
                    minHeight: 48
                },
                '@media (min-width:600px)': { minHeight: 64 }
            }
        },
        components: {
            MuiUseMediaQuery: {
                defaultProps: {
                    ssrMatchMedia: (query) => ({
                        matches: mediaQuery.match(query, {
                            width: config?.device.isMobile ? '0px' : '1200px'
                        })
                    })
                }
            },
            MuiPaper: {
                styleOverrides: {
                    rounded: {
                        borderRadius: 18
                    }
                }
            },
            MuiButton: {
                styleOverrides: {
                    contained: {
                        borderRadius: 12,
                        boxShadow: 'none',
                        fontWeight: 600,
                        textTransform: 'initial'
                    },
                    outlined: {
                        borderRadius: 12,
                        fontWeight: 600,
                        textTransform: 'initial'
                    }
                }
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: 'none',
                        border: '1px solid #ECECEC'
                    }
                }
            },
            MuiTab: {
                styleOverrides: {
                    wrapped: {
                        flexDirection: 'row',
                        '& > :first-child': {
                            marginBottom: '0 !important'
                        }
                    }
                }
            },
            MuiChip: {
                styleOverrides: {
                    label: {
                        color: '#282D31'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {
                    root: {
                        borderRadius: 8
                    }
                }
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        '&:first-child': {
                            paddingLeft: 0
                        },
                        '&:last-child': {
                            paddingRight: 0
                        }
                    }
                }
            }
        },
        palette: {
            common: {
                black: '#4d4d4d',
                white: '#fff'
            },
            mode: 'light',
            default: {
                main: '#4d4d4d',
                light: '#4d4d4d',
                dark: '#4d4d4d',
                contrastText: '#fff'
            },
            primary: {
                main: '#f5726a',
                light: '#f5726a',
                dark: '#f5726a',
                contrastText: '#fff'
            },
            secondary: {
                main: '#fafafa',
                light: '#fff',
                dark: '#bdbdbd',
                contrastText: '#fff'
            },
            info: {
                main: '#0000008a',
                light: '#0000008a',
                dark: '#0000008a',
                contrastText: '#fff'
            },
            error: {
                light: '#e57373',
                main: '#ff6600',
                dark: '#d32f2f',
                contrastText: '#fff'
            }
        },
        customPalette: {
            common: {
                lightGray: '#FAFAFA',
                blueGray: '#8385A3'
            }
        }
    });

export default OrangeTheme;
