import isBrowser from 'utils/is-browser';

export default function waitScriptReady(id: string, options?: { alwaysResolve?: boolean }) {
    if (isBrowser() && '__DEFERRABLE_SCRIPTS__' in window && id in window.__DEFERRABLE_SCRIPTS__) {
        return window.__DEFERRABLE_SCRIPTS__[id]!.promise;
    }
    if (options?.alwaysResolve) {
        return Promise.resolve();
    }
    return Promise.reject(`Script with id = "${id}" not found`);
}
