import { Provider as MobxProvider } from 'mobx-react';
import React from 'react';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { CacheProvider } from '@emotion/react';
import { loadableReady } from '@loadable/component';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { HydrationBoundary, QueryClient, QueryClientProvider } from '@tanstack/react-query';

import createEmotionCache from 'app/create-emotion-cache';
import { createStores } from 'app/store';
import { queryFn } from 'main/api/utils';
import { ConfigsProvider } from 'main/modules/configs';
import { ElasticSearchProvider } from 'main/modules/elastic';
import I18n from 'main/theme/i18n';
import OrangeTheme from 'main/theme/OrangeTheme';

import App from './App';
import SnackBarProvider from './main/pages/components/SnackBarProvider';

import './scss/main.scss';

const hostnameToEnvironmentMap = {
    ['beta.vulners.com']: 'unstable',
    ['alpha.vulners.com']: 'testing',
    ['vulners.com']: 'production'
};

const environment = hostnameToEnvironmentMap[window.location.hostname] || 'development';

if (environment === 'production' || environment === 'unstable') {
    Sentry.init({
        dsn: 'https://afff0c0b9fea4021b656ee39a079ba04@sentry-public.vulners.com//8',
        integrations: [new BrowserTracing()],
        environment,
        // We recommend adjusting this value in production, or using tracesSampler
        // for finer control
        tracesSampleRate: 1.0
    });
}

const { mobxStores, bootstrap } = createStores(window._____APP_STATE_____);
document.i18n = window.i18n = I18n.get;

const cache = createEmotionCache();

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            retry(failureCount, error) {
                if (error instanceof TypeError) {
                    return failureCount < 3;
                }

                return false;
            },
            staleTime: Number.MAX_SAFE_INTEGER,
            refetchOnWindowFocus: false,
            queryFn: queryFn as never
        }
    }
});

/**
 * Run App
 */
loadableReady(() => {
    function Main() {
        React.useLayoutEffect(() => {
            bootstrap();
        }, []);

        return (
            <StyledEngineProvider injectFirst>
                <CacheProvider value={cache}>
                    <ThemeProvider theme={OrangeTheme(window.__CONFIG__)}>
                        <ConfigsProvider value={window.__CONFIG__}>
                            <MobxProvider {...mobxStores}>
                                <QueryClientProvider client={queryClient}>
                                    <HydrationBoundary state={window.__REACT_QUERY_STATE__}>
                                        <ElasticSearchProvider>
                                            <SnackBarProvider>
                                                <BrowserRouter>
                                                    <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
                                                        <App />
                                                    </Sentry.ErrorBoundary>
                                                </BrowserRouter>
                                            </SnackBarProvider>
                                        </ElasticSearchProvider>
                                    </HydrationBoundary>
                                </QueryClientProvider>
                            </MobxProvider>
                        </ConfigsProvider>
                    </ThemeProvider>
                </CacheProvider>
            </StyledEngineProvider>
        );
    }

    const container = document.getElementById('body');
    if (window.__SSR__) {
        hydrateRoot(container!, <Main />);
    } else {
        const root = createRoot(container!);
        root.render(<Main />);
    }
});
