import { inject, observer } from 'mobx-react';
import React from 'react';

import { Button, Dialog } from '@mui/material';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import API from 'app/API';

import Captcha from './../utils/Captcha';

const captchaRef = React.createRef();

@inject('antiBotStore')
@observer
export default class Antibot extends React.Component {
    onSubmit = () => {
        API.fetchWithToken(API.CAPTCHA_VERIFY, {
            captcha: captchaRef.current.getValue()
        }).then(() => {
            this.props.antiBotStore.closeDialog();
            window.location = window.location;
        });
    };

    render() {
        const { antiBotStore } = this.props;

        return (
            <Dialog title="Pass Captcha" open={antiBotStore.antiBotDialogOpen}>
                <DialogTitle>Pass Captcha</DialogTitle>
                <DialogContent>
                    <DialogContentText style={{ textAlign: 'center' }}>
                        Looks like you are a bot. Please, pass captcha to continue.
                    </DialogContentText>

                    <Captcha id="vulners-antibot-captcha" ref={captchaRef} callback={this.onSubmit} />
                </DialogContent>

                {/* TODO Remove Button after success test callback*/}
                <DialogActions>
                    <Button color="primary" onClick={this.onSubmit}>
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
