import { action, computed, makeObservable, observable } from 'mobx';

import API from 'app/API';
import Cacheable from 'app/store/Cacheable';
import ApiKey from 'app/store/models/ApiKey';
import Subscription from 'app/store/models/Subscription';
import User from 'app/store/models/User';
import Webhook from 'app/store/models/Webhook';
import { handleHubSpotOauthRedirect } from 'pages/hooks/use-hubspot-redirection';
import isOkResponse from 'utils/is-ok-response';
import parseDate from 'utils/parse-date';

export default class UserStore extends Cacheable {
    @observable user = new User();

    @observable apiKeys = [];
    @observable editingApiKey = {};
    @observable apiKeysLoaded = false;
    @observable emailSubscriptionsLoaded = false;
    @observable webhookSubscriptionsLoaded = false;

    @observable userLoaded = false;
    @observable licenseListLoaded = false;

    @observable licenseList = [];
    @observable subscriptions = [];
    @observable webhooks = [];

    @observable editingSubscription = {};
    @observable editingWebhook = {};

    @observable isLoading = false;
    @observable licenseListLoading = false;

    @observable licenseAmount = 0;
    @observable licenseSpent = 0;
    @observable isLicenseAmountLoading = false;

    userPromise = Promise.resolve();
    licenseListPromise = Promise.resolve();

    constructor() {
        super();
        makeObservable(this);
        this.editingApiKey = new ApiKey(this);
        this.editingSubscription = new Subscription(this);
    }

    bootstrap() {
        return Promise.all([
            this.loadUser(),
            this.loadLicenseList(),
            this.loadEmailSubscriptions(),
            this.loadWebhookSubscriptions(),
            this.loadLicenseAmount()
        ]);
    }

    consume(cachedEntry) {
        if (cachedEntry.data) {
            this.handleUserLoad(cachedEntry.data);
        }
        if (cachedEntry.userCredits) {
            this.licenseAmount = cachedEntry.userCredits.licenseAmount;
            this.licenseSpent = cachedEntry.userCredits.licenseSpent;
        }
        if (cachedEntry.licenseList) {
            this.handleLicenseListLoad(cachedEntry.licenseList);
        }
    }

    handleUserLoad(data) {
        this.user = new User(data);
        this.userLoaded = true;
        handleHubSpotOauthRedirect({
            email: this.user.email,
            username: this.user.username,
            marketingAgreement: this.user.marketingAgreement > 0
        });
    }

    handleLicenseListLoad(data) {
        this.licenseList = data;
        this.licenseListLoaded = true;
    }

    @action
    loadUser = () => {
        if (this.isLoading) {
            return this.userPromise;
        }

        const request = API.INFO;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.isLoading = true;

        this.userPromise = API.fetch(request)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.handleUserLoad(response.data);
                    this.setItemToCache(queryKey, { data: response.data });
                }
            })
            .finally(() => {
                this.userLoaded = true;
                this.isLoading = false;
            });

        return this.userPromise;
    };

    @action
    logOut = () =>
        API.fetchWithToken(API.LOGOUT, {}).then((response) => {
            if (isOkResponse(response)) {
                this.clearUserInfo();
                this.userLoaded = true;
            }
        });

    @action loadEmailSubscriptions = () => {
        this.emailSubscriptionsLoaded = false;

        return API.fetch(API.EMAIL_SUBSCRIPTIONS_LIST).then((response) => {
            if (isOkResponse(response)) {
                this.emailSubscriptionsLoaded = true;

                this.subscriptions = response.data.subscriptions?.map((item) => new Subscription(this, item)) ?? [];
            }
        });
    };

    @action loadWebhookSubscriptions = () => {
        this.webhookSubscriptionsLoaded = false;

        return API.fetch(API.WEBHOOK_SUBSCRIPTIONS_LIST).then((response) => {
            if (isOkResponse(response)) {
                this.webhookSubscriptionsLoaded = true;
                this.webhooks = response.data.subscriptions?.map((item) => new Webhook(this, item)) ?? [];
            }
        });
    };

    @action
    setEditingSubscription = (subscription, rawModel) =>
        (this.editingSubscription = subscription || new Subscription(this, rawModel || { email: this.user.email }));

    @action
    setEditingWebhook = (webhook, rawModel) => (this.editingWebhook = webhook || new Webhook(this, rawModel));

    @action
    loadApiKeys = (callback) =>
        API.fetch(API.KEY_LIST)
            .then((response) => {
                this.apiKeys = (response.data.apiKeyList || []).map((key) => new ApiKey(this, key));
                this.apiKeysLoaded = true;
            })
            .then(callback);

    @action
    setEditingApiKey = (apiKey) => (this.editingApiKey = apiKey || new ApiKey(this));

    @action
    loadLicenseList = () => {
        if (this.licenseListLoading) {
            return this.licenseListPromise;
        }
        const request = API.USER_LICENSE_LIST;
        const queryKey = request.url;

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.licenseListLoading = true;
        this.licenseListPromise = API.fetch(request)
            .then((response) => {
                if (isOkResponse(response)) {
                    this.handleLicenseListLoad(response.data.licenseList);
                    this.setItemToCache(queryKey, { licenseList: this.licenseList });
                }
            })
            .finally(() => {
                this.licenseListLoading = false;
                this.licenseListLoaded = true;
            });

        return this.licenseListPromise;
    };

    @action
    loadLicenseAmount = async () => {
        const queryKey = '__license_amount__';

        if (this.hasItemInCache(queryKey)) {
            return this.consume(this.getItemFromCache(queryKey));
        }

        this.isLicenseAmountLoading = true;

        try {
            await Promise.all([this.loadUser(), this.loadLicenseList()]);

            if (this.isAuthorized) {
                const [license] = this.licenseList
                    .filter(({ active }) => active)
                    .sort((a, b) => {
                        const aPriority = licenseTypePriorities[a.licenseType] || licenseTypePriorities['__other__'];
                        const bPriority = licenseTypePriorities[b.licenseType] || licenseTypePriorities['__other__'];
                        return aPriority - bPriority;
                    });

                if (license) {
                    const response = await API.fetch(API.USER_CREDIT_STATS, { licenseId: license.id });
                    if (isOkResponse(response)) {
                        this.licenseAmount = response.data.amount;
                        this.licenseSpent = license.requestsCount.reduce(
                            (acc, { count = 0, commercialCount = 0 }) => acc + count + commercialCount,
                            0
                        );
                        this.setItemToCache(queryKey, {
                            userCredits: {
                                licenseAmount: this.licenseAmount,
                                licenseSpent: this.licenseSpent
                            }
                        });
                    }
                }
            }
        } finally {
            this.isLicenseAmountLoading = false;
        }
    };

    @computed get userCredits() {
        return this.user.isAuthorized ? this.user.amount + this.licenseAmount : 0;
    }

    @computed get isUserCreditsLoading() {
        return this.isLoading || this.licenseListLoading || this.isLicenseAmountLoading;
    }

    @computed get hasTrialLicense() {
        return this.licenseList.find((l) => l.licenseType === 'trial');
    }

    @computed get isTrialLicenseExpired() {
        if (this.hasTrialLicense) {
            const license = this.licenseList.find((l) => l.licenseType === 'trial');
            return parseDate(license.licenseExpiration).getTime() < Date.now();
        } else {
            return false;
        }
    }

    @computed get hasFeeLicense() {
        return this.licenseList.find((l) => l.licenseType === 'free');
    }

    @computed get hasOnlyFreeLicense() {
        return this.licenseList.length === 1 && this.licenseList.find((l) => l.licenseType === 'free');
    }

    @computed get hasLicense() {
        return (
            this.licenseList.filter(({ licenseType }) => licenseType !== 'trial' && licenseType !== 'free').length > 0
        );
    }

    @computed get isAuthorized() {
        return this.user.isAuthorized;
    }

    @computed get isAnonymous() {
        return this.user.isAnonymous;
    }

    @action
    setTrialLicense = () => API.fetchWithToken(API.LICENSE_START_TRIAL).then(() => this.loadLicenseList());

    @action
    clearUserInfo() {
        this.user = new User();
        this.apiKeys = [];
        this.subscriptions = [];
        this.licenseList = [];
        this.userLoaded = false;
        this.licenseListLoaded = false;
        this.clearCache();
        API.JWT = '';
    }

    @action
    updateMarketingAgreement = (value) => {
        API.fetch(API.MARKETING_AGREEMENT, { marketingAgreement: value }).then(() => {
            this.user.marketingAgreement = value;
        });
    };
}

export const MarketingAgreementTypes = {
    TRUE: 1,
    FALSE: 0,
    ABSENTED: -1
};

const licenseTypePriorities = {
    ['oem']: 1,
    ['enterprise']: 2,
    ['oem-lite']: 3,
    ['pro']: 4,
    ['__other__']: 5,
    ['trial']: 6,
    ['free']: 7
};
