export function jsonReplacer(_key: string, value: unknown) {
    if (value instanceof Error) {
        if ('toJSON' in value && typeof value.toJSON === 'function') {
            return value;
        } else {
            return errorToJson(value);
        }
    }

    if (typeof value === 'bigint') {
        return value.toString();
    }

    return value;
}

export function errorToJson(error: Error) {
    return {
        name: error.name,
        message: error.message,
        stack: error.stack
    };
}
