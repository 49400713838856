import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { Redirect, Switch } from 'react-router-dom';
import { logger } from 'services/logger';

import loadable from '@loadable/component';

import PageRoute from 'main/components/PageRoute';
import ProtectedPageRoute from 'main/components/ProtectedPageRoute';
import isomorphicLocalStorage from 'main/modules/isomorphic-local-storage';
import isBrowser from 'utils/is-browser';

import Notifications from './main/common/Notifications';
import AntibotDialog from './main/components/dialog/Antibot';
import PasswordRecovery from './main/components/landing/PasswordRecovery';

const SubscribeConfirmation = loadable(() =>
    import(
        /* webpackChunkName: subscribe-confirmation */
        './main/pages/Confirmation'
    ).then(({ SubscribeConfirmation }) => SubscribeConfirmation)
);

const UnSubscribeConfirmation = loadable(() =>
    import(
        /* webpackChunkName: un-subscribe-confirmation */
        './main/pages/Confirmation'
    ).then(({ UnSubscribeConfirmation }) => UnSubscribeConfirmation)
);

const MarketingUnSubscribeConfirmation = loadable(() =>
    import(
        /* webpackChunkName: marketing-un-subscribe-confirmation */
        './main/pages/Confirmation'
    ).then(({ MarketingUnSubscribeConfirmation }) => MarketingUnSubscribeConfirmation)
);

const AboutUs = loadable(() =>
    import(
        /* webpackChunkName: about-us */
        './main/pages/company/AboutUs'
    )
);

const Partners = loadable(() =>
    import(
        /* webpackChunkName: partners */
        './main/pages/company/Partners'
    )
);

const ActivationSuccess = loadable(() =>
    import(
        /* webpackChunkName: activation-success */
        './main/pages/ActivationSuccess'
    )
);

const Trial = loadable(() =>
    import(
        /* webpackChunkName: trial */
        './main/pages/Trial'
    )
);

const TrialLogin = loadable(() =>
    import(
        /* webpackChunkName: trial-login */
        './main/pages/TrialLogin'
    )
);
const TrialRecovery = loadable(() =>
    import(
        /* webpackChunkName: trial-recovery */
        './main/pages/TrialRecovery'
    )
);

const TrialSignUp = loadable(() =>
    import(
        /* webpackChunkName: trial-sign-up */
        './main/pages/TrialSignUp'
    )
);

const Checkout = loadable(() =>
    import(
        /* webpackChunkName: checkout */
        './main/pages/Checkout'
    )
);

const BrandGuideline = loadable(() =>
    import(
        /* webpackChunkName: brandguideline */
        './main/pages/company/BrandGuideline'
    )
);

const Contacts = loadable(() =>
    import(
        /* webpackChunkName: contacts */
        './main/pages/company/Contacts'
    )
);

const Assessment = loadable(() =>
    import(
        /* webpackChunkName: assessment */
        './main/pages/landing/v2/assessment/Assessment'
    )
);
const Feed = loadable(() =>
    import(
        /* webpackChunkName: feed */
        './main/pages/landing/v2/feed/Feed'
    )
);

const PerimeterControl = loadable(() =>
    import(
        /* webpackChunkName: perimetercontrol */
        './main/pages/landing/v2/perimeter-control'
    )
);

const SDK = loadable(() =>
    import(
        /* webpackChunkName: sdk */
        './main/pages/landing/v2/sdk/SDK'
    )
);

const BaseLayout = loadable(() =>
    import(
        /* webpackChunkName: baselayout */
        './main/pages/layout/BaseLayout'
    )
);

const Login = loadable(() =>
    import(
        /* webpackChunkName: login */
        './main/pages/Login'
    )
);

const EnterpriseSignIn = loadable(() =>
    import(
        /* webpackChunkName: enterprise-sign-in */
        './main/pages/EnterpriseSignIn'
    )
);

const MainLayout = loadable(() =>
    import(
        /* webpackChunkName: mainlayout */
        './main/pages/MainLayout'
    )
);

const Pricing = loadable(() =>
    import(
        /* webpackChunkName: pricing */
        './main/pages/Pricing'
    )
);

const Products = loadable(() =>
    import(
        /* webpackChunkName: products */
        './main/pages/Products'
    )
);

const Recovery = loadable(() =>
    import(
        /* webpackChunkName: recovery */
        './main/pages/Recovery'
    )
);

const AgentReport = loadable(() =>
    import(
        /* webpackChunkName: agentreport */
        './main/pages/scan/agent/Index'
    )
);

const AgentWizard = loadable(() =>
    import(
        /* webpackChunkName: agentwizard */
        './main/pages/scan/agent/Wizard'
    )
);

const ApiWizard = loadable(() =>
    import(
        /* webpackChunkName: apiwizard */
        './main/pages/scan/api/Wizard'
    )
);

const Audit = loadable(() =>
    import(
        /* webpackChunkName: audit */
        './main/pages/scan/Audit'
    )
);

const SignUp = loadable(() =>
    import(
        /* webpackChunkName: signup */
        './main/pages/SignUp'
    )
);

const Stats = loadable(() =>
    import(
        /* webpackChunkName: stats */
        './main/pages/stats'
    )
);

const Vscanner = loadable(() =>
    import(
        /* webpackChunkName: vscanner */
        './vscanner/Index'
    )
);

const LandingMain = loadable(() =>
    import(
        /* webpackChunkName: landing */
        './main/pages/landing/v2/Main'
    )
);

const Search = loadable(() =>
    import(
        /* webpackChunkName: search */
        './main/pages/Search/index'
    )
);

const Sitemap = loadable(() =>
    import(
        /* webpackChunkName: sitemap */
        './main/pages/Sitemap'
    )
);

const Bulletin = loadable(() =>
    import(
        /* webpackChunkName: Bulletin */
        './main/pages/Bulletin'
    )
);

const WallarmSearch = loadable(() =>
    import(
        /* webpackChunkName: wlmsearch */
        './main/pages/WallarmSearch'
    )
);

const EmailSubscriptions = loadable(() =>
    import(
        /* webpackChunkName: email */
        './main/pages/Email'
    )
);

const WebhookSubscriptions = loadable(() =>
    import(
        /* webpackChunkName: webhooks */
        './main/pages/Webhook'
    )
);

const UserInfo = loadable(() =>
    import(
        /* webpackChunkName: user-info */
        './main/pages/UserInfo'
    )
);

const Windows = loadable(() =>
    import(
        /* webpackChunkName: windows */
        './main/pages/landing/v2/windows'
    )
);

const Linux = loadable(() =>
    import(
        /* webpackChunkName: linux */
        './main/pages/landing/v2/linux'
    )
);

const Trivy = loadable(() =>
    import(
        /* webpackChunkName: linux */
        './main/pages/landing/v2/trivy'
    )
);

const Faq = loadable(() =>
    import(
        /* webpackChunkName: faq */
        './main/pages/Faq'
    )
);

/** @type {any} */
let Admin = React.Fragment;
try {
    Admin = loadable(() =>
        import(
            /* webpackChunkName: admin */
            './admin/Index'
        )
    );
} catch (error) {
    logger.error(`[Admin]: ${error}`);
}

@inject('userStore')
@inject('antiBotStore')
@observer
class App extends React.Component {
    static propTypes = {
        antiBotStore: PropTypes.object
    };

    state = {
        notifications: process.env.NODE_ENV === 'production' && isBrowser() && new Notifications().start()
    };

    componentDidMount() {
        const gclid = new URLSearchParams(window.location.search).get('gclid');
        gclid && isomorphicLocalStorage.setItem('gclid', gclid);

        document.addEventListener('show-captcha', () => {
            this.props.antiBotStore.openDialog();
        });
    }

    render() {
        return (
            <>
                <Switch>
                    <PageRoute path="/home">
                        <Redirect to="/" />
                    </PageRoute>
                    <PageRoute path="/landing">
                        <Redirect to="/" />
                    </PageRoute>
                    <PageRoute
                        exact
                        path="/"
                        component={LandingMain}
                        title="CVE Database - Security Vulnerabilities and Exploits"
                        description="Vulnerability database enriched with millions CVE, exploits, articles, varied tools and services for vulnerability management against cybersecurity threats"
                    />
                    <PageRoute
                        exact
                        path="/feed"
                        component={Feed}
                        title="Threat Intelligence Feeds for Cyber Security"
                        description="Get customizable cyber security feeds from the most complete and up-to-date security intelligence database"
                    />
                    <PageRoute
                        exact
                        path="/assessment"
                        component={Assessment}
                        title="Vulnerability Assessment Tool and Solution"
                        description="Check your servers environment for known threats in less then 100 milliseconds with zero intrusion using our threat intelligence platform against cyber security threats."
                    />
                    <PageRoute
                        exact
                        path="/sdk"
                        component={SDK}
                        title="Advanced Threat Intelligence Platform"
                        description="Build your own cyber security scanner using just one python programmer in less than a few hours, using our advanced threat intelligence platform."
                    />
                    <PageRoute
                        exact
                        path="/windows"
                        component={Windows}
                        title="Windows Vulnerability Scanner - Vulnerability Assessment Tool"
                        description="Protect your Windows servers from vulnerabilities and threats with Vulners Windows scanner"
                    />
                    <PageRoute
                        exact
                        path="/linux"
                        component={Linux}
                        title="Linux Vulnerability Scanner - Vulnerability Assessment Tool"
                        description="Protect your Linux servers from vulnerabilities and threats with Vulners Linux scanner"
                    />
                    <PageRoute
                        exact
                        path="/perimeter-control"
                        component={PerimeterControl}
                        title="Perimeter Control Tool"
                        description="Monitor your network perimeter for vulnerabilities and threats with Vulners Perimeter Control Tool"
                    />
                    <PageRoute
                        exact
                        path="/trivy"
                        component={Trivy}
                        title="Vulners plugin for Trivy"
                        description="Enchant your Trivy with Vulners plugin and get the most complete and up-to-date vulnerability database"
                    />
                    <PageRoute path="/pricing">
                        <Redirect to="/price-list" />
                    </PageRoute>

                    <ProtectedPageRoute
                        path="/admin"
                        render={() => (
                            <MainLayout hideFooter>
                                <Admin />
                            </MainLayout>
                        )}
                    />

                    <PageRoute path="/trial" exact component={Trial} title="Start trial" />
                    <PageRoute path="/trial/login" exact component={TrialLogin} title="Login" />
                    <PageRoute path="/trial/signup" exact component={TrialSignUp} title="SignUp" />
                    <PageRoute path="/trial/recover-password" component={TrialRecovery} title="Recover Password" />

                    <BaseLayout>
                        <Switch>
                            <PageRoute path="/search" component={Search} />

                            <PageRoute
                                path="/stats"
                                component={Stats}
                                title="Statistics and Analytics"
                                description="Cyber security analytics collected from millions of vulnerabilities, exploits and articles"
                            />
                            <PageRoute
                                path="/help"
                                render={() => {
                                    window.location.replace('/docs');
                                    return null;
                                }}
                            />
                            <PageRoute
                                path="/company/about-us"
                                component={AboutUs}
                                title="About-Us"
                                description="Vulners was founded by a team of security professionals to help companies around the world build vulnerability management and Threat intelligent"
                            />
                            <PageRoute
                                path="/company/contacts"
                                component={Contacts}
                                title="Contacts"
                                description="Vulners sales and technical support teams are always on hand to help you build a vulnerability assessment and threat intelligence cyber security tools"
                            />
                            <PageRoute path="/company/partners" component={Partners} title="Partners" />
                            <PageRoute
                                path="/company/branding"
                                component={BrandGuideline}
                                title="Branding Guideline"
                                description="Vulners brand materials for press releases, b2b integrations and publications"
                            />
                            <PageRoute path="/contacts">
                                <Redirect to="/company/contacts" />
                            </PageRoute>
                            <PageRoute exact path="/brand">
                                <Redirect to="/company/branding" />
                            </PageRoute>

                            <PageRoute
                                path="/subscriptionConfirm"
                                component={SubscribeConfirmation}
                                title="Subscription Confirmation"
                            />
                            <PageRoute
                                path="/subscriptionUnsubscribe"
                                component={UnSubscribeConfirmation}
                                title="Subscription Unsubscribe"
                            />
                            <PageRoute
                                path="/activationsuccess"
                                component={ActivationSuccess}
                                title="Registration activation"
                            />
                            <PageRoute
                                path="/unsubscribe"
                                component={MarketingUnSubscribeConfirmation}
                                title="Marketing unsubscribe"
                            />

                            <PageRoute path="/login" component={Login} title="Login" />
                            <PageRoute
                                path="/enterprise-sign-in"
                                component={EnterpriseSignIn}
                                title="Enterprise Sign In"
                            />
                            <PageRoute path="/signup" component={SignUp} title="SignUp" />
                            <PageRoute path="/recover-password" component={Recovery} title="Recover Password" />
                            <PageRoute
                                path="/passwordrecovery"
                                component={PasswordRecovery}
                                title="Password Recovery"
                            />

                            <ProtectedPageRoute path="/userinfo" component={UserInfo} title="User Info" />

                            <PageRoute
                                path="/email"
                                component={EmailSubscriptions}
                                title="Email Notifications for Vulnerability Feed"
                                description="How to be notified about latest security patches, cyber security threats, 0-days and exploits? Subscribe your security team with Vulners email notifications!"
                            />
                            <PageRoute
                                path="/webhook"
                                component={WebhookSubscriptions}
                                title="Webhook Notifications for Vulnerability Feed"
                                description="How to be automate feed about latest security patches, cyber security threats, 0-days and exploits? Subscribe your security team with Vulners email notifications!"
                            />

                            <PageRoute path="/checkout" component={Checkout} title="Buy Subscription" />

                            <PageRoute
                                path="/price-list"
                                component={Pricing}
                                title="Price List"
                                description="Find out how much you can save on hacker attacks using timely vulnerability assessment management provided by Vulners security tools"
                            />

                            <PageRoute
                                path="/plugins"
                                key="plugins"
                                component={Products}
                                title="Plugins and integrations"
                                description="Improve your cyber security quick and easy using Vulners plugins for popular SIEM, VM and monitoring tools"
                            />
                            <PageRoute path="/products" key="products">
                                <Redirect to="/plugins" />
                            </PageRoute>

                            <PageRoute
                                path="/vscanner"
                                component={Vscanner}
                                title="Perimeter Scanner"
                                description="Protect company network perimeter by mapping ports, collecting CPEs, scanning web, receiving CVEs"
                            />

                            <PageRoute
                                exact
                                path="/scanner"
                                component={AgentReport}
                                title="Vulnerability Scanner Dashboard"
                                description="A fleet of 1,000 Linux and Windows servers can be scanned for known vulnerabilities and cyber security threats in less then 100 milliseconds, get fix instantly"
                            />
                            <PageRoute exact path="/scanner/scan" component={AgentWizard} title="Linux Scanner" />
                            <PageRoute exact path="/scanner/apiscan" component={ApiWizard} title="API Scanner" />
                            <PageRoute exact path="/scanner/audit" component={Audit} title="Linux Audit" />

                            <PageRoute path="/linux-scanner">
                                <Redirect to="/scanner" />
                            </PageRoute>
                            <PageRoute path="/linux-scanner/scan">
                                <Redirect to="/scanner/scan" />
                            </PageRoute>
                            <PageRoute path="/linux-scanner/apiscan">
                                <Redirect to="/scanner/apiscan" />
                            </PageRoute>
                            <PageRoute path="/linux-scanner/audit">
                                <Redirect to="/scanner/audit" />
                            </PageRoute>

                            <PageRoute exact path="/scan">
                                <Redirect to="/linux-scanner" />
                            </PageRoute>
                            <PageRoute exact path="/scan-help">
                                <Redirect to="/linux-scanner/scan" />
                            </PageRoute>
                            <PageRoute exact path="/apiscan-help">
                                <Redirect to="/linux-scanner/apiscan" />
                            </PageRoute>
                            <PageRoute exact path="/apiscan">
                                <Redirect to="/linux-scanner" />
                            </PageRoute>
                            <PageRoute exact path="/audit">
                                <Redirect to="/linux-scanner/audit" />
                            </PageRoute>

                            <PageRoute
                                exact
                                path="/faq"
                                component={Faq}
                                title="F.A.Q."
                                description="Frequently asked questions about Vulners vulnerability database, vulnerability management and threat intelligence platform"
                            />

                            <PageRoute exact path={['/sitemap/:page', '/sitemap']} component={Sitemap} />

                            <PageRoute path="/wallarm/:itemId" component={WallarmSearch} />
                            <PageRoute path="/metasploit/:itemId*" component={Bulletin} />
                            <PageRoute path="/:itemType/:itemId" component={Bulletin} />
                            <PageRoute path="*">
                                <Redirect to="/search" />
                            </PageRoute>
                        </Switch>
                    </BaseLayout>
                </Switch>

                <AntibotDialog />
            </>
        );
    }
}

export default App;
