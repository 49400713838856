import { logger } from 'services/logger';

export default function gtagEvent(eventName: string, args?: null | Record<string, string>) {
    try {
        if (window.gtag) {
            window.gtag('event', eventName, args);
        }
    } catch (e) {
        logger.error(e);
    }
}
