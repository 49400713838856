import { action, computed, makeObservable, observable, reaction, runInAction } from 'mobx';

import API from 'app/API';
import LocalCache from 'main/components/utils/LocalCache';

const LS_KEY = 'SCAN_FILTER_STATE_V2';

const DEFAULT_FILTER = {
    agentip: '',
    agentfqdn: '',
    osname: '',
    osversion: '',
    family: '',
    severity: '',
    vulnID: '',
    tags: ''
};

export class ScanStore {
    @observable skip = 0;
    @observable size = 30;
    @observable sort = '-modified';
    @observable reportType = '';
    @observable agentlist = {};
    @observable scansLoading = false;

    @observable filter = DEFAULT_FILTER;
    @observable reports = {};
    @observable filterOpen = false;

    constructor() {
        makeObservable(this);
        reaction(
            () => this.size,
            () => {
                this.fetchReport();
            }
        );
        reaction(
            () => this.skip,
            () => {
                this.fetchReport();
            }
        );
        reaction(
            () => this.sort,
            () => {
                this.fetchReport();
            }
        );
        reaction(
            () => this.reportType,
            () => {
                this.fetchReport();
            }
        );

        this.filter = LocalCache.getObject(LS_KEY) || DEFAULT_FILTER;
        reaction(
            () => Object.values(this.filter),
            () => {
                LocalCache.setObject(LS_KEY, this.filter);
            }
        );
    }

    @action
    setFilter = (key, value) => {
        if (key === 'tags' && value.length) {
            value = value.split(',').map((tag) => tag.trim());
        }
        this.filter[key] = value;
    };

    @action
    removeFilter = (key) => {
        delete this.filter[key];
    };

    @action
    clearFilter = () => {
        this.filter = DEFAULT_FILTER;
    };

    @action
    setSkip = (value) => {
        this.skip = value;
    };

    @action
    setSize = (value) => {
        this.size = value;
    };

    @action
    setSort = (field, desc) => {
        this.sort = (desc ? '-' : '') + field;
    };

    @action
    fetchReport = () => {
        if (this.scansLoading) {
            return;
        }

        this.scansLoading = true;
        return API.fetch(API.AGENT_REPORTS_VULNS_REPORT, {
            reporttype: this.reportType,
            skip: this.skip,
            size: this.size,
            sort: this.sort,
            filter: Object.entries(this.filter).reduce((a, v) => {
                v[1] && (a[v[0]] = v[1]);
                return a;
            }, {})
        })
            .then((json) => {
                // Test env
                if (process.env.NODE_ENV !== 'production') {
                    if (this.reportType === 'ipsummary') {
                        json = require('../../tests/mocks/ipSummary.json');
                    }
                    if (this.reportType === 'vulnssummary') {
                        json = require('../../tests/mocks/vulnsSummary.json');
                    }
                    if (this.reportType === 'vulnslist') {
                        json = require('../../tests/mocks/vulnsList.json');
                    }
                    if (this.reportType === 'scanlist') {
                        json = require('../../tests/mocks/scanList.json');
                    }
                }
                runInAction(() => {
                    this.reports[this.reportType] = json.data;
                    this.scansLoading = false;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.scansLoading = false;
                });
            });
    };

    @action
    setReportType(value) {
        this.reportType = value;
    }

    @action
    agentListLoad = (newSkip, newSize) => {
        this.scansLoading = true;
        const skip = newSkip || this.skip;
        const size = newSize || this.size;

        API.fetch(API.AGENT_LIST, { skip, size })
            .then((json) => {
                // Test env
                // if (process.env.NODE_ENV !== 'production') {
                //     json = require('../../tests/mocks/agentList.json');
                // }

                runInAction(() => {
                    this.agentlist = json.data;
                    this.size = size;
                    this.skip = skip;
                    this.scansLoading = false;
                });
            })
            .catch(() => {
                runInAction(() => {
                    this.scansLoading = false;
                });
            });
    };

    @computed
    get filterActive() {
        return !!Object.keys(this.filter).length;
    }

    @action
    setFilterOpen = () => (this.filterOpen = !this.filterOpen);
}
